export default async function getCountryandIP() {
  try {
    const response = await fetch("https://ipapi.co/json/");
    const res = await response.json();

    return res;
  } catch (error) {
    // console.log(error);
    return error;
  }
}