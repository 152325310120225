import { create } from 'zustand'

const useSignInOverlay = create((set, get) => ({
  state: 'inactive',
  isActive: () => get().state === 'active',
  setState: (state) => set({ state }),
  closeOverlay: () => set({ state: 'inactive' }),
  openOverlay: () => set({ state: 'active' })
}))

const useSignUpOverlay = create((set, get) => ({
  state: 'inactive',
  isActive: () => get().state === 'active',
  setState: (state) => set({ state }),
  closeOverlay: () => set({ state: 'inactive' }),
  openOverlay: () => set({ state: 'active' })
}))

export { useSignInOverlay, useSignUpOverlay }
