import { validate } from "numeral"
import { useState, useEffect } from "react"
import { useTranslation } from 'next-i18next'

export default function PasswordValidator({password, repeat, setPasswordIsValid}) {
const { t } = useTranslation('auth')

const [lengthCheck,setLengthCheck] = useState(false)
const [upperCaseCheck,setUpperCaseCheck] = useState(false)
const [numberCheck,setNumberCheck] = useState(false)
const [specialCheck, setSpecialCheck] = useState(false)
const [matchCheck, setMatchCheck] = useState(false)
  useEffect(()=>{
    let isValid = true
    //check ic length
    if(password?.length < 8){
      isValid = false
      setLengthCheck(false)
    }else{
      setLengthCheck(true)
    }
    //check for capital letter
    const capitalLetterRegex = /[A-Z]/
    const containsCapitalLetter = capitalLetterRegex.test(password)
    if(!containsCapitalLetter){
      isValid = false
      setUpperCaseCheck(false)
    }else{
      setUpperCaseCheck(true)
    }
    // check for number
    if(!/\d/.test(password)){
      isValid = false
      setNumberCheck(false)
    }else{
      setNumberCheck(true)
    }
    //check for special character
    const specialCharRegex = /[!@#$%^&*()\-_=+[\]{}|;:'",.<>/?\\]/
    const containsSpecialChar = specialCharRegex.test(password)
    if(!containsSpecialChar){
      isValid = false
      setSpecialCheck(false)
    }else{
      setSpecialCheck(true)
    }
    //check password match
    if(!(password === repeat)){
      isValid = false
      setMatchCheck(false)
    }else{
      setMatchCheck(true)
    }
    //update state
    setPasswordIsValid(isValid)
  },[password, repeat])

  return (
    <div className="fs-08">
      <p>
        Password Must:
      </p>
      <p className={lengthCheck ? "password-rule-check" : "password-rule-x"}>{t('passwordRuleLength')}</p>
      <p className={upperCaseCheck ? "password-rule-check" : "password-rule-x"}>{t('passwordRuleCapital')}</p>
      <p className={numberCheck ? "password-rule-check" : "password-rule-x"}>{t('passwordRuleNumbers')}</p>
      <p className={specialCheck ? "password-rule-check" : "password-rule-x"}>{t('passwordRuleSpecial')}</p>
      <p className={matchCheck ? "password-rule-check" : "password-rule-x"}>{t('passwordRuleMatch')}</p>
    </div>
  )
}
